import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';

const faqs = [
  {
    id: 1,
    ques: 'How can I sign up for the Cleartrip for Business products?',
    ans: 'To sign up for the services, you will need to complete the registration process. This typically involves providing your business details, such as your company name, contact information, and any required licenses or certifications. Once your registration is approved, you will receive login credentials to access the portal.',
  },
  {
    id: 2,
    ques: 'What travel products are available on Cleartrip for Business?',
    ans: 'Cleartrip for Business offers a comprehensive range of travel products to meet the needs of travel agents and corporate clients. These products may include domestic and international flights, accommodations (hotels, resorts, serviced apartments), car rentals, airport transfers, travel insurance, and tour packages.',
  },
  {
    id: 3,
    ques: 'How can I make bookings on your various products?',
    ans: 'Once you have logged in, you can use the search functionality to find the desired travel products. Select the preferred options and proceed to the booking stage, where you will enter the passenger details, payment information, and any additional requirements. After confirming the booking, you will receive a confirmation email or voucher with the necessary travel details.',
  },
  {
    id: 4,
    ques: 'Are there any benefits or discounts for business customers?',
    ans: 'Our products offer exclusive benefits and discounts for travel agents and corporate clients. These can include competitive rates, negotiated deals with suppliers, access to special promotions, and loyalty programs.',
  },
];

const FAQ = () => (
  <div className='flex justify-between items-center max-w-screen-md mx-auto mb-16'>
    <Accordion type='multiple' collapsible className='w-full'>
      {faqs.map(({ id, ques, ans }) => (
        <AccordionItem value={id}>
          <AccordionTrigger className='text-xl font-semibold transition-transform duration-300 ease-linear [&[data-state=open]>img]:rotate-45'>
            <>
              {ques}
              <img
                src='/plus-circle.svg'
                alt='plus circle'
                width={32}
                height={32}
                className='transition-transform duration-300 ease-linear'
              />
            </>
          </AccordionTrigger>
          <AccordionContent
            className='max-w-[60ch] font-medium text-base'
            style={{ transition: 'max-height 0.3s ease' }}
          >
            {ans}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  </div>
);

export const FAQs = () => {
  return (
    <section className='text-black pb-48 px-4'>
      <div className='max-w-7xl mx-auto'>
        <h5 className='text-3xl lg:text-5xl text-center font-semibold mb-32'>
          Frequently asked questions
        </h5>
        <FAQ />
      </div>
    </section>
  );
};

export default FAQs;
