export const TopCustomers = () => {
  return (
    <section className='mt-20 flex flex-wrap items-center space-x-12 max-w-max mx-auto gap-y-12 px-2'>
      <img src='/asian-paints.svg' alt='asian paints' width={77} height={65} />
      <img src='/saint-gobain.svg' alt='saint gobain' width={97} height={40} />
      <img src='/tata-aia.svg' alt='tata aia' width={98} height={24} />
      <img src='/blue-star.svg' alt='blue star' width={113} height={21} />
      <img src='/axis-bank.svg' alt='axis bank' width={122} height={33} />
      <img src='/bajaj.svg' alt='bajaj' width={95} height={52} />
      <img src='/icici-bank.svg' alt='icici bank' width={121} height={24} />
    </section>
  );
};

export default TopCustomers;
