import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./modal.css";

const Dialog = ({ open, onCloseModal, children }) => {
  // const onCloseModal = () => setOpen(false);

  return (
    <div className='max-w-[400px] rounded'>
      <Modal open={open} onClose={onCloseModal} center>
        {children}
      </Modal>
    </div>
  );
};

export default Dialog;
