const TermsHero = ({ terms }) => (
  <div style={{ maxWidth: 1320, margin: 'auto' }}>
    {terms.map(({ title, content }) => (
      <div style={{ marginBottom: 20 }}>
        <p style={{ fontSize: 24, fontWeight: 700, marginBottom: 20 }}>
          {title}
        </p>
        {content.map(({ text }) => (
          <p>{text}</p>
        ))}
      </div>
    ))}
  </div>
);

export default TermsHero;
