import { Button } from '../newComponents/ui/button';
import { Reveal } from '../newComponents/utils/reveal';

export const AgentBox = () => {
  return (
    <div className='pb-24'>
      <Reveal amount={0.42}>
        <img
          src='./ooo-showcase.svg'
          alt='Out of Office product'
          width={1220}
          height={742}
          className='hidden mx-auto lg:block'
        />
      </Reveal>

      <div className='flex flex-col justify-center font-medium lg:flex-row align-center lg:-mt-20'>
        <Reveal delay={0.5}>
          <div className='flex flex-col items-center mb-8 lg:mb-0'>
            <img
              src='/agentbox-1.svg'
              alt='Get the Corporate fares with free seat and meal'
              width={288}
              height={178}
            />
            <p className='text-base w-24ch'></p>
          </div>
        </Reveal>
        <Reveal delay={0.7}>
          <div className='flex flex-col items-center mb-8 lg:mb-0'>
            <img
              src='/agentbox-2.svg'
              alt='Customised travel policies for your organisation'
              width={288}
              height={178}
            />
            <p className='text-base w-24ch'></p>
          </div>
        </Reveal>
        <Reveal delay={0.9}>
          <div className='flex flex-col items-center mb-8 lg:mb-0'>
            <img
              src='/agentbox-3.svg'
              alt='Simplified admin panel to manage employee expense'
              width={288}
              height={178}
            />
            <p className='text-base w-24ch'></p>
          </div>
        </Reveal>
        <Reveal delay={1.1}>
          <div className='flex flex-col items-center mb-8 lg:mb-0'>
            <img
              src='/agentbox-4.svg'
              alt='24x7 customer care support for seamless booking'
              width={288}
              height={178}
            />
            <p className='-mt-6 text-base w-24ch'></p>
          </div>
        </Reveal>
      </div>

      <Reveal delay={0.5}>
        <Button
          href='/agent-partners/more-details'
          title='Get started with AgentBox'
          className='mx-auto mt-24 text-white bg-black'
          asInternalLink={true}
        />
      </Reveal>
    </div>
  );
};

export default AgentBox;
