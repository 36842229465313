import Testimony from './testimonials';

const ValueItem = ({ title, subtitle }) => (
  <div className='flex flex-col items-center content-center mt-24 mb-16'>
    <p className='mb-4 text-2xl lg:text-5xl text-clear-orange'>{title}</p>
    <p className='text-center text-black text-md w-16ch'>{subtitle}</p>
  </div>
);

export const ProductValue = () => {
  return (
    <section className='mb-48'>
      <div className='max-w-screen-xl mx-auto'>
        <h4 className='text-3xl font-semibold text-center lg:text-5xl text-neu-black'>
          Why users love us
        </h4>

        <div className='flex flex-wrap justify-center lg:space-x-24'>
          <ValueItem title='100%' subtitle='Maximum Savings & Benefits' />
          <ValueItem title='₹0' subtitle='Setup Fee & No Hidden Costs' />
          <ValueItem title='1st' subtitle='Your trusted no.1 Choice' />
          <ValueItem title='24x7' subtitle='At your Support. Always' />
        </div>
      </div>

      <div className='max-w-screen-xl flex flex-col md:flex-row md:mx-auto gap-4 md:px-28'>
        <Testimony
          content='"We are really impressed by the timely support that you have been providing us. The proactive support is commendable, available round the clock :) Looking forward to your continued support."'
          title={['Madhur Ohri', 'Manager']}
          imageSrc='/zomato.svg'
          imageAlt='Zomato brand'
          bgColor='bg-light-red'
        />
        <Testimony
          content='"We are satisfied with Cleartrip for our travel needs. We appreciate Team Cleartrip for their assistance 24x7 and look forward to continue working with them in future too."'
          title={['Dr. V P Mathur', 'President- HR']}
          imageSrc='/bkt.svg'
          imageAlt='BKT brand'
          bgColor='bg-light-green'
        />
        <Testimony
          content='"Cleartrip customer support team works amazing & very helpful all the times. Even our relationship manager is always supportive 24/7 & solve the issues promptly."'
          title={['Sunita Advani', 'Deputy Manager- Travel']}
          imageSrc='/endress.svg'
          imageAlt='Endress brand'
          bgColor='bg-light-blue'
        />
      </div>
    </section>
  );
};

export default ProductValue;
