import React from 'react';
import Header from '../header/header';
import TermsHero from '../termsHero/termsHero';
import Footer from '../footer/footer';
import { terms } from './terms';

const TermsPage = () => {
  return (
    <div>
      <Header logoHeight={'120px'} location='landingPage' />
      <TermsHero terms={terms} />
      <Footer />
    </div>
  );
};

export default TermsPage;
