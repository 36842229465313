import { Navbar } from './layout/navbar';
import { ProductTabs } from './productTabs';
import ProductUsp from './productUsp';
import TopCustomers from './topCustomers';

export const Hero = ({ handleScrollToFooter }) => {
  return (
    <>
      <section className='min-h-screen bg-hero-gradient bg-no-repeat bg-cover'>
        <Navbar handleScrollToFooter={handleScrollToFooter} />
        <div className='mt-16'>
          <ProductTabs />
          <TopCustomers />
        </div>
      </section>
      <ProductUsp />
    </>
  );
};

export default Hero;
