import { Reveal } from "./utils/reveal";
import { Button } from "./ui/button";
import { cn } from "../lib/utils";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../constants";

const FeatureItem = ({ imageSrc, altSrc, title, subtitle }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const isMobile = breakpoint === "mobile";
  const size = isMobile ? 56 : 72;

  return (
    <Reveal delay={0.5}>
      <div className='flex flex-col items-center'>
        <img src={imageSrc} alt={altSrc} width={size} height={size} />
        <p className='text-2xl lg:text-3xl+ mt-8 mb-2 font-semibold text-neu-black'>
          {title}
        </p>
        <p className='text-base font-medium text-neu-gray w-48ch'>{subtitle}</p>
      </div>
    </Reveal>
  );
};

const Feature = ({
  imageSrc,
  imageWidth,
  imageHeight,
  imageClasses = "mb-6",
  altSrc,
  title,
  subtitle,
  classNames,
}) => (
  <div className={`cfw-feature ${classNames}`}>
    <Reveal amount={0.5}>
      <div className='cfw-feature'>
        <img
          src={imageSrc}
          alt={altSrc}
          width={imageWidth}
          height={imageHeight}
          className={imageClasses}
        />
        <p className='mb-3 text-2xl lg:text-3xl+ text-neu-black font-semibold w-20ch'>
          {title}
        </p>
        <p className='inline-block text-base font-medium lg:text-md text-neu-gray w-32ch lg:w-48ch'>
          {subtitle}
        </p>
      </div>
    </Reveal>
  </div>
);

export const CleartripForWork = () => {
  return (
    <div>
      <div className='grid-rows-5 gap-6 mx-auto lg:grid lg:grid-cols-2 mt-14 max-w-7xl'>
        <Feature
          imageSrc='/mobile-magnify.svg'
          altSrc='mobile'
          imageClasses='mb-16'
          classNames={cn("row-span-3", "py-0")}
          title='Wherever work takes you'
          subtitle='Book Work fares on all participating airlines every time you travel.'
        />

        <Feature
          imageSrc='/cfw-benefits.svg'
          altSrc='cfw benefits'
          imageWidth={241}
          imageHeight={176}
          classNames='row-span-2 py-8'
          title="Unlock benefits you'll love"
          subtitle='Free flight amendments, complimentary meals on-board, and reduced cancellation fees are all included in your Work fare.'
        />

        <div className={cn("row-span-3 cfw-feature flex gap-y-14")}>
          <FeatureItem
            imageSrc='/reports.svg'
            altSrc='reports'
            classNames='row-span-2'
            title='Download travel reports'
            subtitle='All the reports you need, in one place'
          />
          <FeatureItem
            imageSrc='/travel-offers.svg'
            altSrc='travel offers'
            title='Promotional offers'
            subtitle='Access to exclusive prices and special fares'
          />
        </div>

        <Feature
          imageSrc='/work-exclusive.svg'
          altSrc='work exclusive'
          imageWidth={118}
          imageHeight={118}
          imageClasses='mb-8'
          classNames='row-span-2 py-11'
          title='The smarter way to travel, every time'
          subtitle='Thoughtfully designed features and rewards to make your business travel a pleasure.'
        />
      </div>

      <Reveal>
        <Button
          href='https://www.cleartrip.com/cleartripforwork/?utm_source=ooo-landing-page'
          title='Get started with Cleartrip for Work'
          className='mx-auto mt-32 text-white'
        />
      </Reveal>
    </div>
  );
};

export default CleartripForWork;
