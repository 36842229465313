import { cn } from '../lib/utils';

const Testimony = ({ content, title, imageSrc, imageAlt, bgColor }) => {
  const [name = '', designation = ''] = title || [];

  return (
    <div
      className={cn(
        'grid p-4 md:p-8 text-center rounded-xl border border-[#E6E6E6]',
        bgColor
      )}
    >
      <p className='text-xl'>{content}</p>
      <div className='my-11 mx-auto'>
        <p>{name}</p>
        <p>{designation}</p>
      </div>
      <img src={imageSrc} alt={imageAlt} className='mx-auto' />
    </div>
  );
};

export default Testimony;
