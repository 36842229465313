const Footer = () => {
  return (
    <footer className='d-flex flex-wrap justify-content-between align-items-center py-2 bg-light mt-custom-footer lsq-signup-footer'>
      <div className='container'>
        <ul className='nav d-flex justify-content-center w-100 footer-menu'>
          <li className='nav-item me-3'>
            <a
              href='https://www.cleartrip.com/about'
              className='nav-link px-2 text-black'
            >
              About Us
            </a>
          </li>
          <li className='nav-item me-3'>
            <a
              href='https://www.cleartrip.com/faq/'
              className='nav-link px-2 text-black'
            >
              FAQ's
            </a>
          </li>
          <li className="nav-item">
            <a href="/terms" className="nav-link px-2 text-black">
            Terms of Use
            </a>
          </li>
          <li className="nav-item">
            <a href="/privacy" className="nav-link px-2 text-black">
            Privacy Policy
            </a>
          </li>
          <li className='nav-item me-3'>
            <a
              href='https://www.cleartrip.com/cookie-policy/'
              className='nav-link px-2 text-black'
            >
              Cookie Policy
            </a>
          </li>
         
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
