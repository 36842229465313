const terms = [
  {
    title: 'Privacy Policy',
    content: [
      {
        text: (
          <>
            <p>
              We value the trust you place in us and recognize the importance of
              secure transactions and information privacy. This Privacy Policy
              describes how Cleartrip Private Limited (collectively referred to
              as “Cleartrip”, “us”, “our” or “we”), its affiliates and group
              companies, who provide travel related services and leisure
              activities (“Services”) collect, use, share or otherwise process
              your personal information through the websites{' '}
              <a href='https://www.cleartrip.com/'>www.cleartrip.com</a>;{' '}
              <a href='https://agency.cleartrip.com'>agency.cleartrip.com</a>;{' '}
              <a href='https://www.agentbox.com'>www.agentbox.com</a>;{' '}
              <a href='https://ooo.cleartrip.com'>ooo.cleartrip.com</a>;{' '}
              <a href='https://deals.cleartrip.com'>deals.cleartrip.com</a>;{' '}
              <a href='https://www.cleartripforbusiness.com'>
                www.cleartripforbusiness.com
              </a>
              ; <a href='https://corporate.cleartrip.com'>corporate.cleartrip.com</a>{' '}
              and its sub-domains; mobile application and m-site (hereinafter,
              collectively referred to as “Booking Platform”).
            </p>
            <p>
              By visiting the Booking Platform, providing your information or
              availing our Services offered on the Booking Platform, you
              expressly agree to be bound by the terms and conditions of this
              Privacy Policy, the Terms of Use and any applicable Services’
              terms and conditions. Your personal information will be primarily
              stored and processed in India and may have data protection laws
              that are different from those that apply in the country in which
              you are located. By using the Booking Platform and our Services,
              you consent to the transfer of information outside of your country
              of residence. If you do not agree to the Terms of Use or terms of
              this Privacy Policy, please refrain from accessing our Booking
              Platform or using our Services.
            </p>
            <p>
              We encourage you to read this Policy carefully, and to regularly
              check this page to review any changes we might make.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Collection',
    content: [
      {
        text: (
          <>
            <p>
              We may collect your personal information directly from you (e.g.,
              when you visit our Booking Platform); in course of our
              relationship with you (e.g., if you utilize travel services); when
              you make your personal information public (e.g., if you make a
              public post about us on social media); when you download, install
              or use any of our Services. We may also receive information about
              you from third parties (e.g., our travel partners). We may also
              create information about you, such as records of your interactions
              with us or combine data you have provided to us with data obtained
              from third parties such as social networks and other data
              collection entities. Cleartrip is not responsible for personal
              information you volunteer about yourself in the public domains.
              This Privacy Policy does not cover the practices of third parties
              who may provide information about you to Cleartrip. Some of the
              information that we may collect includes but is not limited to:
            </p>
            <ul>
              <li>
                Information provided to us during sign-up/registering or using
                our Booking Platform such as your username or login details;
                e-mail id; contact number(s)
              </li>
              <li>
                Booking information which includes information about your
                travel, Trip ID, PNR details, bookings, co-passengers, travel
                preferences etc.
              </li>
              <li>
                Demographic information such as gender, nationality, age/date of
                birth
              </li>
              <li>
                Location information that describes the precise geographic
                location of your device
              </li>
              <li>
                Purchase and payment related information such as records of
                travel services purchases and prices; invoice records; payment
                records; payment method; cardholder or account-holder name;
                payment amount; and payment date
              </li>
              <li>
                Data either created by you or by a third party and which you
                wish to store on our servers such as image files, documents etc.
              </li>
              <li>
                Information related to any of your co-passengers or any other
                traveler(s) for whom you have made a booking using the Booking
                Platform. In such a case, you must confirm and represent that
                each of the other co-passengers or traveler(s) for whom a
                booking has been made, has agreed to have the information shared
                by you disclosed to us and further be shared by us with the
                concerned service provider(s)
              </li>
              <li>
                Some sensitive personal information such as your health
                information or Covid 19 vaccination status or certificate in
                case you wish to avail any services that requires us to process
                the certificate/vaccination status or other such similar
                information strictly required in order to ensure safety from the
                spread of communicable diseases as required under the local
                regulatory requirements
              </li>
              <li>
                Any other information that you may share with us or collected
                from you while you visit, access or use our Booking Platform or
                contact us for query resolution (e.g., the type of device you
                are using, I.P address, the internet service provider, browsing
                history, device identity, mobile operator, WI FI Connection,
                service set identifier (“SSID”), International Mobile Subscriber
                Identity (“IMSI”), and International Mobile Equipment Identity
                etc.)
              </li>
            </ul>
            <p>
              If you enroll into our loyalty program or participate in third
              party loyalty program offered by us, we will collect and store
              your personal information such as name, contact number, email
              address, communication address, date of birth, gender, zip code,
              lifestyle information, demographic and work details which is
              provided by you to us or a third-party business partner that
              operates platforms where you can earn loyalty points for purchase
              of services or offerings, and/or also redeem them. We will also
              collect your information related to your transactions on Booking
              Platform and such third-party business partner platforms. When
              such a third-party business partner collects your personal
              information directly from you, you will be governed by their
              privacy policies. We shall not be responsible for the third-party
              business partner’s privacy practices or the content of their
              privacy policies, and we request you to read their privacy
              policies prior to disclosing any information.In general, you can
              browse the Booking Platform without telling us who you are or
              revealing any personal information about yourself. Once you give
              us your personal information, you are not anonymous to us. Where
              possible, we indicate which fields are required and which fields
              are optional. You always have the option to not provide
              information, by choosing not to use a particular service or
              feature on the Booking Platform.Our primary goal in collecting
              your personal information is to provide you a safe, efficient,
              smooth, and customized experience. This allows us to provide
              services and features that most likely meet your needs, and to
              customize our Booking Platform to make your experience safer and
              easier. Furthermore, the information we collect will strictly be
              used for legitimate, business, contractual and lawful purposes
              only.If you receive an email, a call from a person/association
              claiming to be from Cleartrip seeking sensitive personal
              information like debit/credit card PIN, net-banking or mobile
              banking password, we request you to never provide such
              information. We at Cleartrip or our partners do not at any time
              connect with you requesting for such information. If you have
              already revealed such information, report it immediately to an
              appropriate law enforcement agency.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Use',
    content: [
      {
        text: (
          <>
            <p>
              We use your personal information to provide the Services you
              request. Some of purposes for which we use your information are:
            </p>
            <li>
              Provide you with the requested services, such as creating a user
              account, processing and confirming your reservation, and
              communicating with you about services requested (e.g., query
              resolution, regarding the feedback, etc.)
            </li>
            <li>
              Analyze your travel or booking interests and provide promotional,
              special offers, coupons, etc.
            </li>
            <li>
              Send communications related to your transactions, bookings, etc.;
              or send any updates to your bookings using emails, text messages,
              instant messaging platforms such as WhatsApp, Telegram, etc.
            </li>
            <li>Send verification emails to ensure your identity</li>
            <li>
              Send you marketing communications about products and services that
              may be relevant to you
            </li>
            <li>
              Scan voice systems for quality and monitoring purposes or for
              improving our security levels
            </li>
            <li>
              Conduct surveys or provide you with information about our Services
              on our Booking Platform.
            </li>
            <li>
              Maintain and improve the Booking Platform, tailor the user
              experience, and for internal training
            </li>
            <li>Protect your information and the Booking Platform</li>
            <li>
              Manage our IT systems i.e., identification and mitigation of
              fraudulent activity; compliance with applicable law; and improving
              our Services
            </li>
            <li>
              Exercising a right or obligation conferred or imposed by law,
              including responding to request and legal demands
            </li>
            <p>
              We will ask for your permission wherever required to allow us
              access to SMS, contacts in your directory, camera, photo gallery,
              location and device information: (i) to send communication
              regarding your orders or other products and services; (ii) enhance
              your experience on the platform and provide you access to the
              products and services offered on the Booking Platform. You
              understand that your access to Services may be affected in the
              event permission is not provided to us. We will occasionally ask
              you to complete surveys conducted either by us or through a third-
              party market research agency. These surveys may ask you for
              personal information, contact information, gender, date of birth,
              demographic information (like pin code, age or income level)
              attributes such as your interests, household or lifestyle
              information, and other such information that you may choose to
              provide. The survey may involve collection of voice data or video
              recordings. Participation in these surveys is purely voluntary in
              nature. We use this data to tailor your experience at our Booking
              Platform, providing you with content that we think you might be
              interested in and to display content according to your
              preferences. By sharing this information with us or by
              participating in such surveys, you consent us in processing your
              personal information.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Cookies',
    content: [
      {
        text: (
          <>
            <p>
              We use data collection devices such as "cookies" on certain pages
              of the Booking Platform to help analyze our web page flow, measure
              promotional effectiveness, and promote trust and safety. "Cookies"
              are small files placed on your hard drive that assist us in
              providing our services. We store page views, clicks and search
              terms used for ad personalization targeting separately from your
              personal information. We can’t identify you personally through the
              use of these cookies, but third parties who provide these cookies
              may be able to. We offer certain features that are only available
              through the use of a "cookie". We also use cookies to allow you to
              enter your password less frequently during a session. Cookies can
              also help us provide information that is targeted to your
              interests. Most cookies are "session cookies," meaning that they
              are automatically deleted from your hard drive at the end of a
              session. You are always free to decline/delete our cookies if your
              browser permits, although in that case you may not be able to use
              certain features on the Booking Platform and you may be required
              to re-enter your password more frequently during a session.
              Additionally, you may encounter "cookies" or other similar devices
              on certain pages of the Booking Platform that are placed by third
              parties. We do not control the use of cookies by third parties. We
              use cookies from third-party partners such as Google Analytics for
              marketing and analytical purposes. Google Analytics helps us
              understand how our customers use the site. You can read more about
              how Google uses your personal information here:
              <a href='https://www.google.com/intl/en/policies/privacy/'>
                https://www.google.com/intl/en/policies/privacy/
              </a>
              . You can also opt-out of Google Analytics here:
              <a href='https://tools.google.com/dlpage/gaoptout'>
                https://tools.google.com/dlpage/gaoptout
              </a>
              . You can control the use of cookies at the individual browser
              level, but if you choose to disable cookies, it may limit your use
              of certain features or functions on the Services. Please see our
              Cookie Policy for more information, including a more in-depth
              explanation of what cookies are, the different types of cookies
              used on the Services, and how to change or delete them.
            </p>
            <p>
              Please see our{' '}
              <a href='https://www.cleartrip.com/cookie-policy'>
                Cookie Policy
              </a>{' '}
              for more information
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Lawful basis for processing your personal information',
    content: [
      {
        text: (
          <>
            <p>We may process your personal information where:</p>
            <ul>
              <li>
                You have given your consent (this legal basis is only used in
                relation to processing that is entirely voluntary, it is not
                used for processing that is necessary for meeting our
                obligations, required under applicable law or for legitimate
                interests)
              </li>
              <li>
                Processing is necessary for a contract between you and us or for
                legitimate interests
              </li>
              <li>Processing is required by applicable law</li>
              <li>
                Processing is necessary for prompt action, such as in events of
                medical emergencies, disasters, and breakdowns of law and order
              </li>
              <li>
                Processing is necessary for reasonable purposes, such as
                whistleblowing, mergers and acquisitions etc.
              </li>
              <li>
                Processing is necessary for legal purposes or where we have a
                valid and legitimate interest in processing.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    title: 'Sharing',
    content: [
      {
        text: (
          <>
            <p>
              We may share/disclose your personal information internally with
              our group companies or with other third parties and affiliates to
              provide you access to products and services offered by them. These
              entities and affiliates may market to you as a result of such
              sharing unless you explicitly opt-out.
            </p>
            <p>
              Some of the instances where we may share your personal information
              are:
            </p>

            <ul>
              <li>
                With any relevant third-party service providers such as an
                airline, hotel, activity service provider(s) who provide the end
                services who may contact you additionally in relation to the
                services you have requested. You should review their Privacy
                Policy and Terms of Use before sharing any information with them
                to understand how they process your personal information
              </li>
              <li>
                With our data processors who provide storage and other related
                services to you on our behalf such as cloud service providers,
                payment gateways, customer service providers, survey
                distributors or any other third-party for the purposes of
                prevention, detection mitigation, and investigation of
                fraudulent or illegal activities related to our Services or
                prosecution of criminal offences or the execution of criminal
                penalties, including safeguarding against and the prevention of
                threats to public security, compliance with our legal
                obligations, enforcement of our user agreement, facilitate our
                marketing and advertising activities.
              </li>
              <li>
                With your consent we may liaise with third party advertising
                service companies for online behavioral advertising or
                otherwise, and perform related services when you use our
                Services. Often, these third-party advertising companies employ
                cookies and other technologies to measure the effectiveness of
                websites, applications and to understand the booking behavior of
                the user. We also engage third party providers to assist with
                the segmentation of this data.
              </li>
              <li>
                With outside professional advisors (such as accountants,
                auditors, or lawyers), subject to binding contractual
                obligations of confidentiality.
              </li>
              <li>
                With our group companies or affiliates who need to know or have
                access to such information in relation to provisioning of
                Services.
              </li>
              <li>
                With government agencies or other authorized law enforcement
                agencies if required to do so by law or in the good faith belief
                that such disclosure is reasonably necessary to respond to
                subpoenas, court orders, or other legal process.
              </li>
              <li>
                With any relevant third-party acquirer(s), in the event that we
                sell or transfer all or any relevant portion of our business or
                assets (including in the event of a reorganization, dissolution
                or liquidation). Should such a transaction occur, that other
                business entity (or the new combined entity) will be required to
                follow this privacy policy with respect to your personal
                information.
              </li>
              <li>
                We adhere to appropriate security measures to protect your
                personal information while we share with them for processing.
                Further, wherever applicable, we bind these third parties with
                appropriate agreements and require them to process your personal
                information only for specific purposes along with
                confidentiality and with appropriate security measures.
              </li>
              <li>
                When you use a co-branded service (a service operated with a
                partner of Cleartrip), or register or otherwise provide
                information on a co-branded site, you grant us permission to
                pass the collected information back to that partner, which may
                include third party service providers whose services are
                embedded into and/or appear within the Services.
              </li>
              <li>
                We may share your information with our partners such as your
                name, email, or other identifier. Our partners may also: (i)
                collect information directly from your device, such as your IP
                address, device ID, advertising ID, and information about your
                browser or operating system; (ii) combine user information about
                you received from Cleartrip with information about you from
                other sites or services; and (iii) place or recognize a unique
                cookie on your browser.
              </li>
            </ul>
            <p>
              Due to the international nature of our business and in connection
              with the purposes set out in this Privacy Policy, we may share
              your personal information internationally with Cleartrip Group of
              companies, such as Cleartrip Private Limited; Cleartrip MEA FZ
              LLC, Flyin Private Limited, and to other third parties located in
              countries that may have different laws and data protection
              compliance requirements to those that apply in the country in
              which you are located. We would ensure appropriate security
              measures while we transfer these information.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Links to other sites',
    content: [
      {
        text: (
          <>
            <p>
              The scope of this Privacy Policy is limited to the Booking
              Platform. Our Booking Platform may provide links to other
              websites. If you are accessing any other third-party websites or
              applications, please refer to their respective privacy policies to
              understand how they process your personal information.Please see
              our Cookie Policy for more information
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Security precautions',
    content: [
      {
        text: (
          <>
            <p>
              To protect your personal information from unauthorised access or
              disclosure, loss or misuse we have adopted reasonable security
              practices and procedures in line with industry standards. Whenever
              you change or access your account information, we offer the use of
              a secure server. The transmission of information is not completely
              secure for reasons beyond our control. We adopt industry standard
              security measures and keep updating our systems from time to time
              to protect your personal information against any hacking or virus
              dissemination. However, by using the Booking Platform, the users
              accept the security implications of data transmission over the
              internet and the world wide web which cannot always be guaranteed
              as completely secure, and therefore, there would always remain
              certain inherent risks regarding use of the Booking Platform.
              Users are responsible for ensuring the protection of login and
              password records for their account. We are not responsible for any
              breach of security occurring on a third-party platform that you
              may decide to transact with.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Use of children information',
    content: [
      {
        text: (
          <>
            <p>
              Use of our Booking Platform is available only to persons who can
              form a legally binding contract under the Indian Contract Act,
              1872. We do not knowingly solicit or collect personal information
              from children under the age of 18 years. If you have shared any
              personal information of children under the age of 18 years, you
              represent that you have the authority to do so and to permit us to
              use the information in accordance with this Privacy Policy.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Data retention',
    content: [
      {
        text: (
          <>
            <p>
              We retain your personal information for a period no longer than is
              required for the purpose for which it was collected or as required
              under any applicable law. Online data related to online behavioral
              advertising is kept by Cleartrip for not more than 365 days after
              which it will expire, subject to certain conditions. However, the
              365 days period may commence again if the same user subsequently
              visits or interacts with an ad, email, the Services or a channel.
              We may retain data related to you if there is a legal obligation
              to retain the data; if required to comply with any applicable
              statutory or regulatory retention requirement by law; if we
              believe it may be necessary to prevent fraud or future abuse; to
              enable Cleartrip to exercise its legal rights and/or defend
              against legal claims; or for other legitimate purposes. We may
              continue to retain your data in anonymized form for analytical and
              research purposes.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Your rights',
    content: [
      {
        text: (
          <>
            <p>
              We take every reasonable step to ensure that your personal
              information that we process is accurate and, where necessary, kept
              up to date, and any of your personal information that we process
              that you inform us is inaccurate (having regard to the purposes
              for which they are processed) is erased or rectified. You may
              access, correct and update your personal information directly
              through the functionalities provided on the Booking Platform. You
              may delete certain non-mandatory information or delete your user
              account by logging into our website and visiting profile and
              settings sections. You can also write to us at dpo@cleartrip.com
              to assist you with these requests.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Consent',
    content: [
      {
        text: (
          <>
            <p>
              By visiting our Booking Platform or by providing your information,
              you consent to the collection, use, storage, disclosure and
              otherwise processing of your information (including sensitive
              personal information) on the Booking Platform in accordance with
              this Privacy Policy. If you disclose to us any personal
              information relating to other people or make booking on their
              behalf, you represent that you have the authority to do so and to
              permit us to use and share the information in accordance with this
              Privacy Policy.
            </p>
            <p>
              You, while providing your personal information over the Platform
              or any partner platforms or establishments, consent to us
              (including our other corporate entities, affiliates, partners,
              technology partners, marketing channels, business partners and
              other third parties) to contact you through SMS, instant messaging
              apps, call and/or e-mail for the purposes specified in this
              Privacy Policy.
            </p>
            <p>
              You have an option to withdraw your consent that you have already
              provided by writing to us at dpo@cleartrip.com. Please mention
              “for withdrawal of consent” in your subject line of your
              communication. We will verify such requests before acting on our
              request. Please note, however, that withdrawal of consent will not
              be retroactive and will be in accordance with the terms of this
              Privacy Policy, related terms of use and applicable laws. In the
              event you withdraw consent given to us under this Privacy Policy,
              may hamper your access to the Booking Platform or restrict
              provision of our Services for which we consider such information
              to be necessary.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Choice/Opt-Out',
    content: [
      {
        text: (
          <>
            <p>
              We provide all users with the opportunity to opt-out of receiving
              non-essential (promotional, marketing-related) communications from
              us on behalf of our partners, and from us in general, after
              setting up an account. If you do not wish to receive promotional
              communications from us then please unsubscribe by clicking on the
              unsubscribe link in the email or write to us at dpo@cleartrip.com
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Advertisements on Platform',
    content: [
      {
        text: (
          <>
            <p>
              We use third-party advertising companies to serve ads when you
              visit our Platform. These companies may use information (not
              including your name, address, email address, or telephone number)
              about your visits to this and other websites in order to provide
              advertisements about goods and services of interest to you. You
              have the option to opt-out from tracking personalized advertising
              using the “Opt out of Ads Personalization” settings using your
              device's settings application. Cleartrip will have no access to
              your GAID once you select this feature.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Changes to this privacy policy',
    content: [
      {
        text: (
          <>
            <p>
              Please check our Privacy Policy periodically for changes. We may
              update this Privacy Policy to reflect changes to our information
              practices. We will alert you to significant changes by posting the
              date our Privacy Policy got last updated, placing a notice on our
              Booking Platform, or by sending you an email when we are required
              to do so by applicable law. Your continued access to or use of any
              of the Services shall be deemed your acceptance of the Privacy
              Policy.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Contact us',
    content: [
      {
        text: (
          <>
            <p>
              If you have a query, concern, or complaint in relation to
              collection or usage of your personal information under this
              Privacy Policy, please contact us at dpo@cleartrip.com
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Grievance Officer',
    content: [
      {
        text: (
          <>
            <p>
              In accordance with Information Technology Act 2000 and rules made
              there under, the name and contact details of the Grievance Officer
              are provided below:
            </p>
            <p>David Lima</p>
            <p>Asst. Manager - Consumer Grievances</p>
            <p>Cleartrip Pvt Ltd,</p>
            <p>Embassy Tech Village, Tower A, Outer Ring Rd,</p>
            <p>Devarabeesanahalli, Bellandur, Bengaluru, Karnataka 560103</p>
            <br />
            <p>Email ID - wecare@cleartrip.com</p>
            <p>(Please mention your Trip ID while sending an email)</p>
            <p>
              For any Trip ID related services, log on to{' '}
              <a href='https://www.cleartrip.com/support'>www.cleartrip.com/support</a>{' '}
              or call Customer Care No : +91-9595333333 (24 X 7) along with your
              Trip ID.
            </p>
          </>
        ),
      },
    ],
  },
];

export { terms };
