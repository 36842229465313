import Header from "../header/header";
import HeroSection from "../heroSection/heroSection";
import TopCustomers from "../topCustomers/topCustomers";
import ProductFeatures from "../productFeatures/productFeatures";
import SignupForm from "../signupForm/signupForm";
import Footer from "../footer/footer";

import HDFC_bank from "../../assets/images/hdfc.png";
import Axis_Bank_logo_logotype from "../../assets/images/axis-bank.png";
import Bajaj_Logo from "../../assets/images/bajaj.png";
import ICICI_Bank_Logo from "../../assets/images/icici-logo.png";

import import_export from "../../assets/images/import-export.png";
import integration_instructions from "../../assets/images/integration-instructions.png";
import ticket from "../../assets/images/ticket.png";
import settings_ethernet from "../../assets/images/settings_ethernet.png";
import check_circle from "../../assets/images/check-circle.png";
import timeline from "../../assets/images/timeline.png";
import data_usage from "../../assets/images/data-usage.png";
import support_agent from "../../assets/images/support-agent.png";
import person_pin from "../../assets/images/person_pin.png";
import { useState } from "react";

const ApiCustomersPage = () => {
  const [isApiPage] = useState(true);
  const ProductFeaturesimages = [
    {
      id: 1,
      Icons: import_export,
      text: " The fastest response and requests returned",
    },
    {
      id: 2,
      Icons: integration_instructions,
      text: "Easy implementation (Go live within 28 days*)",
    },
    {
      id: 3,
      Icons: ticket,
      text: " Real-time booking and auto ticketing enable customer delight",
    },
    {
      id: 4,
      Icons: settings_ethernet,
      text: "Intelligent management of look to book",
    },
    {
      id: 5,
      Icons: check_circle,
      text: "Uptime of 99.99%",
    },
    {
      id: 6,
      Icons: timeline,
      text: "Real time MIS data",
    },
    {
      id: 7,
      Icons: data_usage,
      text: "View and download intelligent booking reports – Business Summary,  Deposit Accounts etc",
    },
    {
      id: 8,
      Icons: support_agent,
      text: "Dedicated support team to manage cancellations & amendments",
    },
    {
      id: 9,
      Icons: person_pin,
      text: "Technical Integration support throughout the entire project",
    },
  ];

  const images = [
    {
      id: 1,
      src: HDFC_bank,
      alt: "HDFC_bank",
    },
    {
      id: 2,
      src: Axis_Bank_logo_logotype,
      alt: "Axis_Bank_logo_logotype",
    },
    {
      id: 3,
      src: Bajaj_Logo,
      alt: "Bajaj_Logo",
    },
    {
      id: 4,
      src: ICICI_Bank_Logo,
      alt: "ICICI_Bank_Logo",
    },
  ];
  return (
    <div>
      <Header
        isMenuEnable={true}
        logoHeight={"100px"}
        location="apiCustomers"
      />
      <HeroSection
        isLandingPage={false}
        isApiPage={isApiPage}
        HeroSectionHeading="Innovative technology stack ensuring that clients receive excellent service."
        HeroSectionParagraph="Provide a seamless travel booking experience for your organization!"
      />
      <TopCustomers images={images} />
      <ProductFeatures ProductFeaturesimages={ProductFeaturesimages} />
      <SignupForm
        SignupFormHeading="Want to know more about our API product?"
        SignupFormParagraph="Fill in the below details & we'll reach out to you!"
      />
      <Footer />
    </div>
  );
};

export default ApiCustomersPage;
