import { useState } from 'react';
import useBreakpoint from 'use-breakpoint';
import { BREAKPOINTS } from '../../constants';
import { motion } from 'framer-motion';

const Tabs = ({ tabsData, activeTab, onTabChange }) => {
  const [activeIndex, setActiveIndex] = useState(activeTab);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const isDesktop = breakpoint === 'desktop';

  const handleTabClick = (index) => {
    setActiveIndex(index);
    if (onTabChange) {
      onTabChange(index);
    }
  };

  return (
    <div>
      <ul className='flex px-2 py-1 mx-auto space-x-4 text-base font-semibold rounded-full w-max bg-white/20'>
        {tabsData.map(({ label, labelMobile, tabId }) => (
          <li key={tabId}>
            <button
              className={`${
                tabId === activeIndex ? '' : ''
              }  px-4 rounded-full py-2 text-xs md:text-base relative`}
              onClick={() => handleTabClick(tabId)}
            >
              {isDesktop ? label : labelMobile}
              {activeIndex === tabId && (
                <motion.span
                  layoutId='bubble'
                  className='absolute inset-0 z-10 px-2 py-2 font-semibold text-base text-black bg-white rounded-full tracking-tight'
                  transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
                >
                  {isDesktop ? label : labelMobile}
                </motion.span>
              )}
            </button>
          </li>
        ))}
      </ul>
      <div className='tabs-content'>{tabsData[activeIndex].content}</div>
    </div>
  );
};

export { Tabs };
