import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import CorporateCustomersPage from './components/corporateCustomersPage/corporateCustomersPage';
import AgentPartnersPage from './components/agentPartnersPage/agentPartnersPage';
import AncillaryPartnersPage from './components/ancillaryPartnersPage/ancillaryPartnersPage';
import ApiCustomersPage from './components/apiCustomersPage/apiCustomersPage';
import GstBookingsPage from './components/gstBookingsPage/gstBookingsPage';
import TermsPage from './components/termsPage/termsPage';
import PrivacyPage from './components/privacyPage/privacyPage';
import Home from './pages/home';
import ConfLanding from './pages/ConfLanding';

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const mainContext = React.createContext();

function App() {
  return (
    <>
      <mainContext.Provider value={params.utm_source}>
        <Router>
          <Routes>
            <Route
              exact
              path='/corporate-customers/:id'
              element={<CorporateCustomersPage params />}
            />
            <Route
              exact
              path='/agent-partners/:id'
              element={<AgentPartnersPage params />}
            />
            <Route
              exact
              path='/ancillary-partners/:id'
              element={<AncillaryPartnersPage params />}
            />
            <Route
              exact
              path='/api-customers/:id'
              element={<ApiCustomersPage params />}
            />
            <Route
              exact
              path='/gst-bookings/:id'
              element={<GstBookingsPage params />}
            />
            <Route
              exact
              path='/corporate-customers'
              element={<CorporateCustomersPage params />}
            />
            <Route
              exact
              path='/agent-partners'
              element={<AgentPartnersPage params />}
            />
            <Route
              exact
              path='/ancillary-partners'
              element={<AncillaryPartnersPage params />}
            />
            <Route
              exact
              path='/api-customers'
              element={<ApiCustomersPage params />}
            />
            <Route
              exact
              path='/gst-bookings'
              element={<GstBookingsPage params />}
            />
            <Route exact path='/terms' element={<TermsPage params />} />
            <Route exact path='/privacy' element={<PrivacyPage params />} />
            <Route exact path='ipscon' element={<ConfLanding />} />
            <Route exact path='/' element={<Home />} />
          </Routes>
        </Router>
      </mainContext.Provider>
    </>
  );
}

export default App;
export { mainContext };
