import Header from "../header/header";
import HeroSection from "../heroSection/heroSection";
import TopCustomers from "../topCustomers/topCustomers";
import ProductFeatures from "../productFeatures/productFeatures";
import SignupForm from "../signupForm/signupForm";
import Footer from "../footer/footer";

import randstad from "../../assets/images/randstad.png";
import ldslogo from "../../assets/images/lds-logo.jpg";
import flipkart from "../../assets/images/flipkart.png";

import check_circle from "../../assets/images/check-circle.png";
import supportAgent from "../../assets/images/support-agent.png";
import integration from "../../assets/images/integration-instructions.png"
import globe from "../../assets/images/globe.svg"
import settings_ethernet from "../../assets/images/settings_ethernet.png"
import timeline from "../../assets/images/timeline.png";

const AncillaryPartnersPage = () => {
  const ProductFeaturesimages = [
    {
      id: 1,
      Icons: supportAgent,
      text: "A dedicated or mainstream assistance in all steps of the booking",
    },
    {
      id: 2,
      Icons: integration,
      text: "Automated invoicing and reconciliation for efficient billing processes",
    },
    {
      id: 3,
      Icons: globe,
      text: "Experts in sourcing in domestic and international inventory",
    },
    {
      id: 4,
      Icons: settings_ethernet,
      text: "Strong network with partners for best service and value",
    },
    {
      id: 5,
      Icons: check_circle,
      text: "Curated packages filter the options to offer you the best available deals & prices",
    },
    {
      id: 6,
      Icons: timeline,
      text: "Real-time financial data and insights with efficient and accurate financial management",
    },
  ];

  const images = [
    {
      id: 1,
      src: randstad,
      alt: "randstad",
    },
    {
      id: 2,
      src: ldslogo,
      alt: "lds-logo",
    },
    {
      id: 3,
      src: flipkart,
      alt: "flipkart",
    },
  ];
  return (
    <div>
      <Header
        isMenuEnable={true}
        logoHeight={"100px"}
        location='ancillaryPartners'
      />
      <HeroSection
        isLandingPage={false}
        HeroSectionHeading='Streamline your group bookings and events'
        HeroSectionParagraph='Efficient MICE planning and Visa services for stress-free travel.'
      />
      <TopCustomers images={images} />
      <ProductFeatures ProductFeaturesimages={ProductFeaturesimages} />
      <SignupForm
        SignupFormHeading='Want to know more about our ancillary product?'
        SignupFormParagraph="Fill in the below details & we'll reach out to you!"
      />
      <Footer />
    </div>
  );
};

export default AncillaryPartnersPage;
