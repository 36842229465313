import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import oooLogo from "../../assets/images/logo1.png";
import agentpartnersLogo from "../../assets/images/logo2.png";
import ancillarypartnersLogo from "../../assets/images/ancillaryLogo.svg";
import apiLogo from "../../assets/images/logo3.png";
import gstLogo from "../../assets/images/logo4.png";

const Header = ({ isMenuEnable, logoHeight, location }) => {
  const scrollDown = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };
  return (
    <nav className="navbar mrt-2 mrb-2">
      <div className="container-fluid container-lg">
        {location === "landingPage" && (
          <Link to="/">
            <span className="navbar-brand">
              <img src={logo} height={logoHeight} alt="Cleartrip" />
            </span>
          </Link>
        )}
        {location === "ooo" && (
          <Link to="/">
            <span className="navbar-brand">
              <img src={oooLogo} height={logoHeight} alt="Cleartrip" />
            </span>
          </Link>
        )}
        {location === "agentPartners" && (
          <Link to="/">
            <span className="navbar-brand">
              <img
                src={agentpartnersLogo}
                height={logoHeight}
                alt="Cleartrip"
              />
            </span>
          </Link>
        )}
        {location === "ancillaryPartners" && (
          <Link to="/">
            <span className="navbar-brand">
              <img
                src={ancillarypartnersLogo}
                height={logoHeight}
                alt="Cleartrip"
              />
            </span>
          </Link>
        )}
        {location === "apiCustomers" && (
          <Link to="/">
            <span className="navbar-brand">
              <img src={apiLogo} height={logoHeight} alt="Cleartrip" />
            </span>
          </Link>
        )}
        {location === "gstBookings" && (
          <Link to="/">
            <span className="navbar-brand">
              <img src={gstLogo} height={logoHeight} alt="Cleartrip" />
            </span>
          </Link>
        )}
        {isMenuEnable && (
          <div className="button-container">
            <a
              type="button"
              href="https://b2bsignup.cleartrip.com/"
              className="btn btn-secondary me-3 primary-font"
            >
              Login
            </a>
            <button
              type="button"
              onClick={scrollDown}
              className="btn btn-primary primary-font"
            >
              Sign up
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
