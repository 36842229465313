import Header from "../header/header";
import HeroSection from "../heroSection/heroSection";
import ProductFeatures from "../productFeatures/productFeatures";
import SignupForm from "../signupForm/signupForm";
import Footer from "../footer/footer";

// import AsianPaints from "../../assets/images/asian-paints.png";
// import SaintGobain from "../../assets/images/saint-gobain.png";
// import TataAia from "../../assets/images/tata-aia.png";
// import BlueStar from "../../assets/images/blue-star.png";

import gst from "../../assets/images/gst.png";
import date_range from "../../assets/images/date-range.png";
import cup from "../../assets/images/cup.png";
import money from "../../assets/images/money.png";
import timeline from "../../assets/images/timeline.png";

const GstBookingsPage = () => {
  const ProductFeaturesimages = [
    {
      id: 1,
      Icons: gst,
      text: " Avail GST Input Credit on every booking, to maximize your savings ",
    },
    {
      id: 2,
      Icons: date_range,
      text: "Free Amendments: Make changes to your travel plans, with minimum cancellation charges!",
    },
    {
      id: 3,
      Icons: cup,
      text: " Avail complimentary in-fight meals as per the choice!",
    },
    {
      id: 4,
      Icons: money,
      text: "Additional cashback on every booking on Corporate Fares",
    },
    {
      id: 5,
      Icons: timeline,
      text: "All the reports you need, in one place! Organize & analyse your travel bookings.",
    },
  ];
  // const images = [
  //   {
  //     id: 1,
  //     src: AsianPaints,
  //     alt: "AsianPaints",
  //   },
  //   {
  //     id: 2,
  //     src: SaintGobain,
  //     alt: "SaintGobain",
  //   },
  //   {
  //     id: 3,
  //     src: TataAia,
  //     alt: "TataAia",
  //   },
  //   {
  //     id: 4,
  //     src: BlueStar,
  //     alt: "BlueStar",
  //   },
  // ];
  return (
    <div>
      <Header isMenuEnable={true} logoHeight={"100px"} location='gstBookings' />
      <HeroSection
        isLandingPage={false}
        HeroSectionHeading='Save more with an authorized GST document'
        // HeroSectionParagraph='Provide a seamless travel booking experience for your organization!'
      />
      {/* <TopCustomers images={images} /> */}

      <div className='gstProducts'>
        <ProductFeatures ProductFeaturesimages={ProductFeaturesimages} />
      </div>
      <SignupForm
        SignupFormHeading='Want to know more about our agency product?'
        SignupFormParagraph="Fill in the below details & we'll reach out to you!"
      />
      <Footer />
    </div>
  );
};

export default GstBookingsPage;
