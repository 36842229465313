const ApiKeys = {
  public_auth_key:
    "NlFUNGNKYXdTZG5BUTkzek10YmJBVlhUUTV4RWhMVGlXTVlTNVI1S3lLVVJUcHYzUlhab3JrYnl4OEs5VFBuOVB0ejFMRGRDTjlaS3Z0RlZ1VWF5NEg3VHMwYi9teWFuSDJXU0M3Y01GdmJYUG9WdVVFY2VZRW0zUEdNS0dJSk82Ri8wWDBHd01sc25RMHVFcmNvVU5HS3hZbWdCNXNidU1NbWF2SWJraEFjMm1iMFoxZlR1QWdjbUJ3WTNQMzVWMFVycFMwNnZKalpqYnVnblRSa20yUT09",
  public_key:
    "177f0e4ecf2092adbbea415301db3fce1df569683013a3d6f59e06ceeaf81501MX60527",
  autologin_auth_key:
    "f1337de558d8dac571eef96327fc16ab6f691cb6b45a362f600e792839af7636MX60527",
  endpoints: {
    register: "Authentication/V2/Register",
    getOtp: "Authentication/SigninOTP/Get",
    autoLogin: "AutoLogin/Generate",
    getOwner: "UserManagement.svc/User/Retrieve/ByEmailAddress",
    leadUpdate: "LeadManagement.svc/Lead.Update",
  },
  portal_id: "234dc698-2cec-11ed-a87f-0a5db97dc116",
  // base_url: "https://portalapi-in21.leadsquared.com/api/",
  redirect_url: "https://b2bsignup.cleartrip.com/Dashboard",
  lappInfo: {
    baseUrl: "https://lapps-in21.leadsquared.com/executebylapptoken",
    name: "da_60527_efb5b3d7",
    stage: "Live",
    xApiKey: "268eaf4d504047f68be3f2f80c8377ec",
  },
};

export default ApiKeys;
