const ProductFeatures = ({ ProductFeaturesimages }) => {
    return (
      <div className='container-fluid container-lg'>
        <h1 className='text-center title-spaces'>Product Features</h1>
        <div className='row y-gutter-custom mx-md-2'>
          {ProductFeaturesimages.map((item, index) => {
            return (
              <div key={index} className='col-md-4'>
                <img alt="i" src={item.Icons} className='mb-3 h-25' />
                <p className='w-75 w-100-custom'>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  
  export default ProductFeatures;
  