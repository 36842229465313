import { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import useBreakpoint from 'use-breakpoint';
import { BREAKPOINTS } from '../constants';

export const ProductShowcase = ({
  className,
  titleOne,
  titleTwo,
  titleThree = '',
  imageSrc,
  altSrc,
  width = 118.85,
  height = 24,
  children,
}) => {
  const targetRef = useRef(null);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const isDesktop = breakpoint === 'desktop';
  const { scrollYProgress } = useScroll({
    offset: ['start 0.75', '0 0'],
    target: targetRef,
  });

  const scrollProgress1 = [0.1, 0.2, 0.4, 0.5];
  const scrollProgress2 = [0.5, 0.6, 0.78, 0.82];
  const scrollProgress3 = [0.82, 1, 1.1];

  const opacityValue = [0, 1, 1, 0];

  const opacityFor1 = useTransform(
    scrollYProgress,
    scrollProgress1,
    opacityValue
  );

  const opacityFor2 = useTransform(
    scrollYProgress,
    scrollProgress2,
    opacityValue
  );

  const opacityFor3 = useTransform(scrollYProgress, scrollProgress3, [0, 1, 1]);
  const zeroTo32px = useTransform(
    scrollYProgress,
    [0.65, 0.74],
    ['0px', '32px']
  );

  return (
    <motion.section
      ref={targetRef}
      style={{
        marginLeft: isDesktop ? zeroTo32px : 0,
        marginRight: isDesktop ? zeroTo32px : 0,
        borderRadius: isDesktop ? zeroTo32px : 0,
      }}
      className={`${className} min-h-screen px-2 lg:px-0 text-black text-center pt-14 pb-24 mb-32 lg:pt-28 lg:mb-72`}
    >
      <motion.div>
        <div className='grid grid-cols-[1fr] mx-auto max-w-7xl'>
          <motion.h3
            style={{
              opacity: opacityFor1,
            }}
            className='col-start-1 row-start-1 mt-6 mb-6 text-3xl font-semibold lg:mt-12 lg:text-5xl lg:mb-12'
          >
            {titleOne}
          </motion.h3>
          <motion.h3
            style={{
              opacity: opacityFor2,
            }}
            className='col-start-1 row-start-1 mt-6 mb-6 text-3xl font-semibold lg:mt-12 lg:text-5xl lg:mb-12'
          >
            {titleTwo}
          </motion.h3>
          <motion.div
            style={{ opacity: opacityFor3 }}
            className='col-start-1 row-start-1 mt-6 mb-4 lg:mt-12 lg:mb-24'
          >
            {titleThree ? (
              <h3 className='mb-2 text-3xl font-semibold lg:text-5xl'>
                {titleThree}
              </h3>
            ) : null}
            <img
              src={imageSrc}
              alt={altSrc}
              width={width}
              height={height}
              className='mx-auto'
            />
          </motion.div>
        </div>
        {children}
      </motion.div>
    </motion.section>
  );
};

export default ProductShowcase;
