import { Reveal } from './utils/reveal';

export const ProductUsp = () => {
  return (
    <section className='mx-auto mt-64 max-w-max'>
      <Reveal>
        <h2 className='text-3xl lg:text-5xl+ font-semibold tracking-tight text-black text-center'>
          Why Cleartrip for Business
        </h2>
      </Reveal>

      <div className='flex flex-col items-center justify-center mx-2 text-black mt-28 mb-80 gap-y-4 lg:flex-row lg:items-start lg:space-x-16 lg:gap-y-0'>
        <Reveal delay={0.5}>
          <div className='flex flex-col items-center content-center'>
            <img
              src='15-plus-years.svg'
              alt='15+ years of experience'
              width={85}
              height={109}
              className='h-28'
            />
            <p className='mt-6 text-center w-20ch'>
              15+ years in business. Most trusted B2B travel platform
            </p>
          </div>
        </Reveal>

        <Reveal delay={0.7}>
          <div className='flex flex-col items-center'>
            <img
              src='/data-security.svg'
              alt='Your data. Your activity. Highest privacy.'
              width={85}
              height={109}
              className='h-28'
            />
            <p className='mt-6 text-center w-20ch'>
              Your data. Your activity. Highest privacy.
            </p>
          </div>
        </Reveal>

        <Reveal delay={0.9}>
          <div className='flex flex-col items-center'>
            <img
              src='/flight-inventory.svg'
              alt='Largest inventory of flights and hotels available'
              width={85}
              height={109}
              className='h-28'
            />
            <p className='mt-6 text-center w-20ch'>
              Largest inventory of flights and hotels available
            </p>
          </div>
        </Reveal>

        <Reveal delay={1.1}>
          <div className='flex flex-col items-center'>
            <img
              src='flipkart-adani.svg'
              alt='A Flipkart group company. Strategic partnership with the Adani group'
              width={85}
              height={109}
              className='h-28'
            />
            <p className='mt-6 text-center w-20ch'>
              A Flipkart group company. Strategic partnership with the Adani
              group
            </p>
          </div>
        </Reveal>
      </div>
    </section>
  );
};

export default ProductUsp;
