const HeroSection = ({
  isLandingPage,
  // isApiPage,
  HeroSectionSubHeading,
  HeroSectionHeading,
  HeroSectionParagraph,
}) => {
  return (
    <div className='position-relative'>
      <div className='hdrBg'>
        <div className='container-fluid pt-4 container-lg pl-0'>
          <div className='row mx-md-2'>
            <div className='col-md-12 d-flex align-items-center px-4 px-sm-0 mobile-custom'>
              {isLandingPage ? (
                <div className='CustomHeight'>
                  <h1 className='mb-4'>
                    One stop destination for all your travel needs
                  </h1>
                  <ul className='list-unstyled d-flex align-items-left flex-column'>
                    <li className='py-3 d-flex align-items-center custom-li'>
                      <img
                        alt="i"
                        src={require("../../assets/icons/star.png")}
                        className='img-fluid'
                      />{" "}
                      <span className='ps-4'>
                        Exclusive fares for our Business <br />
                        Customers
                      </span>
                    </li>
                    <li className='py-3 d-flex align-items-center custom-li'>
                      <img
                        alt="i"
                        src={require("../../assets/icons/settings.png")}
                        className='img-fluid'
                      />{" "}
                      <span className='ps-4'>
                        All new Simplified portal, built for your organisation's
                        custom needs
                      </span>
                    </li>
                    <li className='py-3 d-flex align-items-center custom-li'>
                      <img
                        alt="i"
                        src={require("../../assets/icons/database.png")}
                        className='img-fluid'
                      />{" "}
                      <span className='ps-4'>
                        Manage your travel needs more efficiently with data
                        reports on the go!
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className='mx-auto'>
                  <div className='text-uppercase mb-4 herostx'>
                    <span>{HeroSectionSubHeading}</span>
                  </div>
                  <h1 className='mb-4 heading-max'>{HeroSectionHeading}</h1>
                  <h3 className='text-black sectionpara-max'>{HeroSectionParagraph}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
