import { useRef } from 'react';
import ProductShowcase from '../newComponents/productShowcase';
import AgentBox from '../newComponents/agentBox';
import OutOfOffice from '../newComponents/outofoffice';
import Hero from '../newComponents/hero';
import CleartripForWork from '../newComponents/cleartripForWork';
import Api from '../newComponents/api';
import FAQs from '../newComponents/faqs';
import Footer from '../newComponents/layout/footer';
import ProductValue from '../newComponents/productValue';
import useBreakpoint from 'use-breakpoint';
import { BREAKPOINTS } from '../constants';
import './home.css';

const Home = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const isDesktop = breakpoint === 'desktop';
  const ref = useRef();
  const handleScrollToFooter = () => {
    if (ref) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Hero handleScrollToFooter={handleScrollToFooter} />
      <ProductShowcase
        className='bg-cfw'
        titleOne='Made for Corporate Employees'
        titleTwo='Search. Save. Book.'
        imageSrc='/cfw.svg'
        altSrc='cleartrip for work'
        width={isDesktop ? 398.29 : 140}
        height={isDesktop ? 51 : 32}
      >
        <CleartripForWork />
      </ProductShowcase>
      <ProductShowcase
        className='bg-ooo'
        titleOne='Made for Corporate Travel'
        titleTwo='Manage. Control. Approve.'
        titleThree='OutOfOffice'
        imageSrc='/cleartrip-xs.svg'
        altSrc='Out Of Office'
        width={isDesktop ? 118.85 : 74.94}
        height={isDesktop ? 24 : 15.13}
      >
        <OutOfOffice />
      </ProductShowcase>
      <ProductShowcase
        className='bg-agentBox'
        titleOne='Made for Travel Agent'
        titleTwo='Search. Share. Book.'
        titleThree='AgentBox'
        imageSrc='/cleartrip-xs.svg'
        altSrc='AgentBox'
        width={isDesktop ? 118.85 : 74.94}
        height={isDesktop ? 24 : 15.13}
      >
        <AgentBox />
      </ProductShowcase>
      <ProductShowcase
        className='text-white bg-black'
        titleOne='Simplified for Developer'
        titleTwo='Integrate. Manage. Scale.'
        titleThree='API'
        imageSrc='/cleartrip-xs.svg'
        altSrc='API '
        width={isDesktop ? 118.85 : 74.94}
        height={isDesktop ? 24 : 15.13}
      >
        <Api />
      </ProductShowcase>
      <ProductValue />
      <FAQs />
      <Footer ref={ref} />
    </>
  );
};

export default Home;
