import { Link } from "react-router-dom";

const Button = ({ title, className, href, asInternalLink }) => {
  const Comp = asInternalLink ? Link : "a";

  return (
    <Comp {...(asInternalLink ? {to: href} : {href: href})} className={`button ${className}`}>
      {title}
    </Comp>
  );
};

export { Button };
