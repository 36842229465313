import { Tabs } from './ui/tabs';
import { ProductPanel } from './productPanel';

const TabsData = [
  // {
  //   tabId: 0,
  //   label: 'Cleartrip for Work',
  //   labelMobile: 'CFW',
  //   content: (
  //     <ProductPanel
  //       title='Make your business travel a pleasure'
  //       titleWidth='lg:w-16ch'
  //       subtitle='Exclusive corporate fares and flexibility benefits when you book with Cleartrip for Work. No subscriptions, no fees and GST Invoice'
  //       buttonTitle='Get started with Cleartrip for Work'
  //       linkHref='https://www.cleartrip.com/cleartripforwork/?utm_source=ooo-landing-page'
  //       imageList={[
  //         { imageSrc: '/free-date-change.svg', imageAlt: 'free date change' },
  //         {
  //           imageSrc: '/free-seats-meals.svg',
  //           imageAlt: 'free seats & meals',
  //         },
  //         {
  //           imageSrc: '/low-cancellation-fee.svg',
  //           imageAlt: 'low cancellation fee',
  //         },
  //         {
  //           imageSrc: '/exclusive-work-fare.svg',
  //           imageAlt: 'exclusive work fare',
  //         },
  //       ]}
  //     />
  //   ),
  // },
  {
    tabId: 0,
    label: 'Corporates',
    labelMobile: 'Corp',
    content: (
      <ProductPanel
        title='Business travel, now for your entire business!'
        subtitle="The new OutOfOffice platform, with customizations as per your organization's needs."
        buttonTitle='Get started with OutOfOffice'
        linkHref='/corporate-customers/more-details'
        imageList={[
          { imageSrc: '/approval-based.svg', imageAlt: 'approval based' },
          {
            imageSrc: '/realtime-dashboard.svg',
            imageAlt: 'realtime dashboard',
          },
          {
            imageSrc: '/negotiated-rates.svg',
            imageAlt: 'negotiated-rates',
          },
          {
            imageSrc: '/maximum-flexibility.svg',
            imageAlt: 'maximum-flexibility',
          },
        ]}
      />
    ),
  },
  {
    tabId: 1,
    label: 'Travel Agent',
    labelMobile: 'Agent',
    content: (
      <ProductPanel
        title='Be a part of India’s fastest growing agency network'
        subtitle='Brand new AgentBox tool, with easy-to-use features and access to the best deals!'
        subtitleWidth='lg:w-48ch'
        buttonTitle='Get started with AgentBox'
        linkHref='/agent-partners/more-details'
        imageList={[
          {
            imageSrc: '/multi-fare-family.svg',
            imageAlt: 'multi fare familiy',
          },
          {
            imageSrc: '/highest-earning.svg',
            imageAlt: 'highest earning',
          },
          {
            imageSrc: '/no-convenience-fee.svg',
            imageAlt: 'no convenience fee',
          },
          {
            imageSrc: '/24x7-customer-support.svg',
            imageAlt: '24x7 customer support',
          },
        ]}
      />
    ),
  },
  {
    tabId: 2,
    label: 'Developers',
    labelMobile: 'API',
    content: (
      <ProductPanel
        title='Innovative technology stack ensuring excellent sevice'
        titleWidth='lg:w-24ch'
        subtitle='Get access to all travel related APIs, with integration of the Cleartrip for Business API. Industry best figures of runtime & response rates!'
        subtitleWidth='lg:w-56ch'
        buttonTitle='Get started as API Partner'
        linkHref='/api-customers/more-details'
        imageList={[
          {
            imageSrc: '/integration-support.svg',
            imageAlt: 'tech support during integration',
          },
          { imageSrc: '/best-pricing.svg', imageAlt: 'best pricing' },
          {
            imageSrc: '/realtime-mis.svg',
            imageAlt: 'real time mis data',
          },
          {
            imageSrc: '/intelligent-look-book.svg',
            imageAlt: 'iintelligent look to book',
          },
        ]}
      />
    ),
  },
];

export const ProductTabs = () => (
  <>
    <Tabs tabsData={TabsData} activeTab={0} />
  </>
);

export default ProductTabs;
