export const BRAND_LOGO = '/brand.svg';
export const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

export const TOP_CUSTOMERS = [
  {
    src: '/asian-paints.svg',
    alt: 'asian paints',
    width: 77,
    height: 65,
  },
  {
    src: '/saint-gobain.svg',
    alt: 'saint gobain',
    width: 97,
    height: 40,
  },
  { src: '/tata-aia.svg', alt: 'tata aia', width: 98, height: 24 },
  { src: '/blue-star.svg', alt: 'blue star', width: 113, height: 21 },
  { src: '/axis-bank.svg', alt: 'axis bank', width: 122, height: 33 },
  { src: '/bajaj.svg', alt: 'bajaj', width: 95, height: 52 },
  { src: '/icici-bank.svg', alt: 'icici bank', width: 121, height: 24 },
  { src: '/flipkart-adani.svg', alt: 'flipkart adani', width: 125, height: 67 },
  {
    src: '/gateway-holiday.svg',
    alt: 'gateway holiday',
    width: 219,
    height: 49,
  },
];
