const TopCustomers = ({images}) => {
    return (
        <div className="bg-light">
        <div className="py-5 top-mt">
            <div className="container">
                <h1 className="text-center title-spaces">Our Top Customers</h1>
                <div className="align-items-center d-flex flex-wrap justify-content-around rowGap">
                    {
                        images.map((item)=>{
                            return <img key={item.id} src={item.src} alt={item.alt} className="m-auto img-fluid custom-h" />
                        })
                    }
                </div>
            </div>
        </div>
        </div>
    );
};

export default TopCustomers;
