import Header from "../header/header";
import HeroSection from "../heroSection/heroSection";
import TopCustomers from "../topCustomers/topCustomers";
import ProductFeatures from "../productFeatures/productFeatures";
import SignupForm from "../signupForm/signupForm";
import Footer from "../footer/footer";

import gold_gateway from "../../assets/images/gold-gateway.png";
import pashankar from "../../assets/images/pashankar.png";
import holiday_triangle from "../../assets/images/holiday-triangle.png";
import maya_travels from "../../assets/images/maya-travels.png";

import check_circle from "../../assets/images/check-circle.png";
import Percent from "../../assets/images/percent.png";
import account_tree from "../../assets/images/account-tree.png";
import date_range from "../../assets/images/date-range.png";
import dashboard_customize from "../../assets/images/dashboard-customize.png";
import import_export from "../../assets/images/import-export.png";
const AgentPartnersPage = () => {
  const ProductFeaturesimages = [
    {
      id: 1,
      Icons: check_circle,
      text: " Assured commission while booking for travelers with Cleartrip for Agent",
    },
    {
      id: 2,
      Icons: Percent,
      text: "Sort flights and hotels by commission %",
    },
    {
      id: 3,
      Icons: account_tree,
      text: " Create Sub-Agents and expand your Business",
    },
    {
      id: 4,
      Icons: date_range,
      text: "Hold seats on international flights temporarily while you wait for customer confirmation.",
    },
    {
      id: 5,
      Icons: dashboard_customize,
      text: "Implement customizable mark-ups: as a percentage, flat fee or service charge.",
    },
    {
      id: 6,
      Icons: import_export,
      text: "Import PNRs from Gallileo GDS etc directly to your agency portal",
    },
  ];

  const images = [
    {
      id: 1,
      src: gold_gateway,
      alt: "gold_gateway",
    },
    {
      id: 2,
      src: pashankar,
      alt: "pashankar",
    },
    {
      id: 3,
      src: holiday_triangle,
      alt: "holiday_triangle",
    },
    {
      id: 4,
      src: maya_travels,
      alt: "maya_travels",
    },
  ];
  return (
    <div>
      <Header
        isMenuEnable={true}
        logoHeight={"100px"}
        location='agentPartners'
      />
      <HeroSection
        isLandingPage={false}
        // HeroSectionSubHeading="CLEARTRIP FOR AGENT"
        HeroSectionHeading='One stop travel solution for all our Agency partners!'
        HeroSectionParagraph='Provide a seamless travel booking experience for your travellers!'
      />
      <TopCustomers images={images} />
      <ProductFeatures ProductFeaturesimages={ProductFeaturesimages} />
      <SignupForm
        SignupFormHeading='Want to know more about our agency product?'
        SignupFormParagraph="Fill in the below details & we'll reach out to you!"
      />
      <Footer />
    </div>
  );
};

export default AgentPartnersPage;
