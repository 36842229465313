import axios from "axios";
import ApiKeys from "../config/apiKeys";

const RegisterUser = (data) => {
    const body = JSON.stringify({
        method: "post",
        headers: {
            "x-lsq-registertoken": ApiKeys.public_key,
            Authorization: ApiKeys.public_auth_key,
            "x-lsq-portalid": ApiKeys.portal_id,
            "Content-Type'": "application/json",
        },
        endpoint: ApiKeys.endpoints.register,
        params: "",
        data: {
            RegisterOnlyNewLead: false,
            LeadFields: [
                {
                    Attribute: "mx_Portal_Confirm_Password",
                    Value: "Samplepassword@123",
                },
                {
                    Attribute: "mx_Portal_Password",
                    Value: "Samplepassword@123",
                },
                {
                    Attribute: "EmailAddress",
                    Value: data,
                },
            ],
        },
    });

    const config = {
        method: "post",
        url: `${ApiKeys.lappInfo.baseUrl}?name=${ApiKeys.lappInfo.name}&stage=${ApiKeys.lappInfo.stage}&xapikey=${ApiKeys.lappInfo.xApiKey}`,
        headers: {
            "x-api-key": ApiKeys.lappInfo.xApiKey,
            "Content-Type": "application/json",
        },
        data: body,
    };

    return axios(config);
};

const getOwnerByEmail = (OwnerEmail) => {
    const body = JSON.stringify({
        method: "get",
        headers: {
            "Content-Type'": "application/json",
        },
        endpoint: ApiKeys.endpoints.getOwner,
        params: { emailAddress: OwnerEmail },
    });

    const config = {
        method: "post",
        url: `${ApiKeys.lappInfo.baseUrl}?name=${ApiKeys.lappInfo.name}&stage=${ApiKeys.lappInfo.stage}&xapikey=${ApiKeys.lappInfo.xApiKey}`,
        headers: {
            "x-api-key": ApiKeys.lappInfo.xApiKey,
            "Content-Type": "application/json",
        },
        data: body,
    };

    return axios(config);
};

const updateOwner = (ownerId, leadId) => {
    const body = JSON.stringify({
        method: "update",
        headers: {
            "Content-Type'": "application/json",
        },
        endpoint: ApiKeys.endpoints.leadUpdate,
        params: {leadId: leadId},
        data: {ownerId: ownerId}
    });

    const config = {
        method: "post",
        url: `${ApiKeys.lappInfo.baseUrl}?name=${ApiKeys.lappInfo.name}&stage=${ApiKeys.lappInfo.stage}&xapikey=${ApiKeys.lappInfo.xApiKey}`,
        headers: {
            "x-api-key": ApiKeys.lappInfo.xApiKey,
            "Content-Type": "application/json",
        },
        data: body,
    };

    return axios(config);
};

const AutoLogin = (leadId) => {
    const body = JSON.stringify({
        method: "post",
        // url: `${ApiKeys.base_url}${ApiKeys.endpoints.autoLogin}?authkey=${ApiKeys.autologin_auth_key}`,
        headers: {
            "Content-Type'": "application/json",
        },
        endpoint: ApiKeys.endpoints.autoLogin,
        params: { authkey: ApiKeys.autologin_auth_key },
        data: {
            LeadId: leadId,
            PortalId: ApiKeys.portal_id
        },
    });

    const config = {
        method: "post",
        url: `${ApiKeys.lappInfo.baseUrl}?name=${ApiKeys.lappInfo.name}&stage=${ApiKeys.lappInfo.stage}&xapikey=${ApiKeys.lappInfo.xApiKey}`,
        headers: {
            "x-api-key": ApiKeys.lappInfo.xApiKey,
            "Content-Type": "application/json",
        },
        data: body,
    };

    return axios(config);
};

const gstDetailUpdate = (leadId, panNo) => {
    const body = JSON.stringify({
        method: "karza",
        headers: {
            "Content-Type'": "application/json",
        },
        endpoint: '',
        params: '',
        data: {
            leadId: leadId,
            panNo: panNo
        },
    });

    const config = {
        method: "post",
        url: `${ApiKeys.lappInfo.baseUrl}?name=${ApiKeys.lappInfo.name}&stage=${ApiKeys.lappInfo.stage}&xapikey=${ApiKeys.lappInfo.xApiKey}`,
        headers: {
            "x-api-key": ApiKeys.lappInfo.xApiKey,
            "Content-Type": "application/json",
        },
        data: body,
    };

    return axios(config);
};





export { RegisterUser, getOwnerByEmail, AutoLogin, updateOwner, gstDetailUpdate };

