import React from 'react';
import { BRAND_LOGO, BREAKPOINTS } from '../../constants';
import useBreakpoint from 'use-breakpoint';

export const Footer = React.forwardRef((_, ref) => {
  const date = new Date();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const isMobile = breakpoint === 'mobile';

  return (
    <footer className='bg-black' ref={ref}>
      <div className='flex flex-col items-center justify-between px-4 pt-16 pb-24 mx-auto max-w-7xl md:flex-row'>
        <div className=''>
          <a href='/' rel='noopener noreferrer'>
            <img
              src={BRAND_LOGO}
              alt='OOO by Cleartrip Logo'
              width={168}
              height={56}
            />
          </a>
        </div>

        {!isMobile ? (
          <ul className='flex items-center space-x-1'>
            <li>© {date.getFullYear()} Cleartrip Pvt. Ltd. </li>
            <li className='flex items-center space-x-1'>
              <span> · </span>
              <a
                href='https://www.cleartrip.com/privacy'
                className='hover-text'
              >
                Privacy
              </a>
              <span> · </span>
            </li>
            <li className='flex items-center space-x-1'>
              <a
                href='https://www.cleartrip.com/security'
                className='hover-text'
              >
                Security
              </a>
              <span> · </span>
            </li>
            <li>
              <a href='https://www.cleartrip.com/terms' className='hover-text'>
                Terms of Use
              </a>
            </li>
          </ul>
        ) : (
          <div className='space-x-1 text-white mt-8'>
            <p className='mb-0 w-full text-white ml-5'>
              © {date.getFullYear()} Cleartrip Pvt. Ltd.
            </p>
            <br />
            <div className='flex items-center -mt-4'>
              <p className='flex items-center space-x-1 text-white'>
                {!isMobile && <span> · </span>}
                <a
                  href='https://www.cleartrip.com/privacy'
                  className='text-white'
                >
                  Privacy
                </a>
                <span> · </span>
              </p>
              <p className='flex items-center space-x-1 text-white'>
                <a
                  href='https://www.cleartrip.com/security'
                  className='text-white'
                >
                  &nbsp;Security
                </a>
                <span> · </span>
              </p>
              <p>
                <a
                  href='https://www.cleartrip.com/terms'
                  className='text-white'
                >
                  &nbsp;Terms of Use
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
});

export default Footer;
