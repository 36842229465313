import { BRAND_LOGO } from '../../constants';

export const Navbar = ({ handleScrollToFooter }) => {
  return (
    <nav className='bg-navbar-border bg-[length:100vw_1px] bg-bottom bg-no-repeat pt-8 pb-4'>
      <div className='mx-auto max-w-7xl px-3'>
        <div className='flex items-center justify-between w-full'>
          <a href='/' rel='noopener noreferrer'>
            <img
              src={BRAND_LOGO}
              alt='OOO by Cleartrip Logo'
              width={168}
              height={56}
            />
          </a>

          {/* <ul className='flex space-x-12 text-base text-white'>
            <li onClick={handleScrollToFooter} className='cursor-pointer'>
              Support
            </li>
          </ul> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
