import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './signupForm.css';
import {
  RegisterUser,
  getOwnerByEmail,
  AutoLogin,
  updateOwner,
  gstDetailUpdate,
} from '../../api';
import ApiKeys from '../../config/apiKeys';
import { mainContext } from '../../App';
// /* global lsq_setupForm */
// /* global lsq_setupPortalProcess */

const scrollToRef = (ref) => {
  ref.current?.scrollIntoView({ behavior: 'smooth' });
};
const SignupForm = ({ SignupFormHeading, SignupFormParagraph }) => {
  const location = useLocation();
  const ownerName = useContext(mainContext);
  let [isFormLoaded, setIsFormLoaded] = useState(false);

  let accountManagerValue = window.localStorage.getItem('utm_source') || '';

  accountManagerValue = accountManagerValue
    ? `${accountManagerValue}@cleartrip.com`
    : 'connect.b2b@cleartrip.com';

  let ownerEmail;
  if (ownerName) {
    ownerEmail = `${ownerName}@cleartrip.com`;
  } else {
    ownerEmail = `naresh.jadhav@cleartrip.com`;
  }

  let { id } = useParams();
  const myRef = useRef(null);
  const formId = '49b9a0ee-ed32-11ec-8065-02dfc2d2ef6a';
  const lsqAuthKey =
    'K1Mzb21MM1NOM21sS1BscWN2K2VFWUV2RVdLdE5FR2UwSzlvZTA3emszMVJFTG96ZnM2c3VkUFJIMFZYQUJmU2c4QlJBL2tZNWJLbURkYWlncUZkNEtuVS9rVyttTWpRRFpqdU91Y2RsQXZDclB6Z3lLZ3dJajFoRTRTZlJ5ZXRRb2lybjJwYnNmM0J5cUtrODVVUHBydTdYZ1dsSksxaDV3Zm9qOGNXL2tBa0pKSWQyOW1SZHJMVmptbVFTTXFFZGx3QjBBc2RtT1lkSlNzNW1GemFqZz09';
  const lsqApiServiceUrl = 'https://portalapi-in21.leadsquared.com/api/Form';
  const lsqAppUrl = 'https://in21.leadsquared.com';

  let emailField;
  let emailValue;
  let ownerId;
  let panValue;
  let panField;
  let phoneNumberField;
  let accountManagerField;
  let managerIdLabel;

  const onLSQFormLoadcomplete = () => {
    try {
      if (location.pathname.includes('corporate-customers')) {
        document.getElementById(
          '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__mx_LeadType_for_Signup__Lead__0'
        ).value = 'Corporate';
      } else if (location.pathname.includes('agent-partners')) {
        document.getElementById(
          '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__mx_LeadType_for_Signup__Lead__0'
        ).value = 'Travel Agency';
      } else if (location.pathname.includes('ancillary-partners')) {
        document.getElementById(
          '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__mx_LeadType_for_Signup__Lead__0'
        ).value = 'Ancillary';
      } else if (location.pathname.includes('api-customers')) {
        document.getElementById(
          '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__mx_LeadType_for_Signup__Lead__0'
        ).value = 'API';
      } else if (location.pathname.includes('gst-bookings')) {
        document.getElementById(
          '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__mx_LeadType_for_Signup__Lead__0'
        ).value = 'CFW';
      }

      emailField = document.getElementById(
        '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__EmailAddress__Lead__0'
      );
      emailField.addEventListener('blur', () => {
        emailValue = emailField.value;
      });

      panField = document.getElementById(
        '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__mx_Pan_Card_Number__Lead__0'
      );
      panField.addEventListener('blur', () => {
        panValue = panField.value;
      });
      document.getElementsByClassName(
        'lsq-form-ctrl-label-container-block'
      )[12].style.display = 'none';
      const submitButton = window.lsqFormContainer.querySelector(
        '.lsq-form-action-btn'
      );
      if (submitButton) {
        submitButton.textContent = 'Sign Up';
      }

      var elements = document.getElementsByClassName("lsq-form-action-btn");

      // Loop through elements and attach click event
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", function () {
          
          // Get the element by its ID
          var element = document.getElementById(
            "83b32ea3-dda9-11ec-8065-02dfc2d2ef6a_tab1_section1"
          );
          var footer = document.querySelector(".lsq-signup-footer");

          // Remove the class from the element
          element.classList.remove("collapse");
          element.style.height = "auto";

          if (window.innerWidth > 991) {
            footer.style.marginTop = "600px";
          }
        });
      }

      phoneNumberField = document.getElementById(
        'FF_83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__Phone__Lead__0'
      );
      phoneNumberField.querySelector('.arrow').classList.add('arrow-custom');
      phoneNumberField.querySelector('.selected-flag').style.width = '24px';
      phoneNumberField.querySelector('.flag-input').value = '+91';

      managerIdLabel = document.createElement('label');
      managerIdLabel.style.marginTop = '12px';
      managerIdLabel.style.marginBottom = '6px';
      managerIdLabel.textContent = 'Account Manager ID';

      accountManagerField = document.getElementById(
        '83b32ea3-dda9-11ec-8065-02dfc2d2ef6a__tab1__section1__mx_Account_manager_ID__Lead__0'
      );
      accountManagerField.readOnly = true;

      if (accountManagerField.parentNode.querySelector('label') === null) {
        accountManagerField.parentNode.insertBefore(
          managerIdLabel,
          accountManagerField
        );
      }
      accountManagerField.value = accountManagerValue;
      setIsFormLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onLSQFormSubmissionSuccess = async () => {
    try {
      const registerApiResponse = await RegisterUser(emailValue);
      const leadId = registerApiResponse.data.message.LeadId;
      if (leadId) {
        try {
          const getOwnerByEmailRes = await getOwnerByEmail(ownerEmail);
          if (getOwnerByEmailRes.data.message.length === 0) {
            let getOwnerByEmailRes = await getOwnerByEmail(
              `naresh.jadhav@cleartrip.com`
            );
            ownerId = getOwnerByEmailRes.data.message[0].UserId;
          } else {
            ownerId = getOwnerByEmailRes.data.message[0].UserId;
          }
          if (ownerId) {
            try {
              let updateOwnerResponse = await updateOwner(ownerId, leadId);
              if (updateOwnerResponse) {
                try {
                  let gstDetailResponse = await gstDetailUpdate(
                    leadId,
                    panValue
                  );
                  if (gstDetailResponse) {
                    try {
                      const autoLoginResponse = await AutoLogin(leadId);
                      if (autoLoginResponse) {
                        const autoLoginAuthKey =
                          autoLoginResponse.data.message.AuthKey;
                        const redirectURL = `${ApiKeys.redirect_url}?leadID=${leadId}&portalid=${ApiKeys.portal_id}&leadid=${leadId}&opportunityId=${leadId}&authkey=${autoLoginAuthKey}`;
                        window.location.href = redirectURL;
                      }
                    } catch (err) {
                      console.log('error = ', err);
                    }
                  }
                } catch (err) {
                  console.log('error = ', err);
                }
              }
            } catch (err) {
              console.log('error = ', err);
            }
          }
        } catch (err) {
          console.log('error = ', err);
        }
      }
    } catch (err) {
      console.log('error = ', err);
    }
  };

  useEffect(() => {
    if (id === 'signup') {
      scrollToRef(myRef);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.lsq_setupPortalProcess({
      id: formId,
      templateName: 'Card',
      themeOptions: {
        primaryButtonColor: '#3366CC',
      },
    });
    if (window.lsqFormContainer) {
      window.lsqFormContainer.addEventListener(
        'lsqformloadcomplete',
        onLSQFormLoadcomplete
      );
      window.lsqFormContainer.addEventListener(
        'lsqformsubmissionsuccess',
        onLSQFormSubmissionSuccess
      );
      // window.lsqFormContainer.addEventListener(
      //   "LsqFormSaveFailure",
      //   onLSQFormSaveFailure
      // );
      // window.lsqFormContainer.addEventListener(
      //   "LSQFormLoadError",
      //   onLSQFormLoadError
      // );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormLoaded]);

  return (
    <div className='bg-gradients top-space mb-signup-form' ref={myRef}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 d-flex align-items-center'>
            <div>
              <h1 className='mb-4'>{SignupFormHeading}</h1>
              <h3 className='mb-5 text-black'>{SignupFormParagraph}</h3>
            </div>
          </div>
          <div className='col-lg-6 position-relative'>
            <div className='position-absolute end-0 start-0 custom-forms'>
              <div
                id='lsq-form-modal'
                data-process-id={formId}
                ref={(elem) => (window.lsqFormContainer = elem)}
                className='modal-v4 fullscreen external lsq-external-form-container'
              >
                <div className='lsq-form-container-wrapper'></div>
                <div className='lsq-form-hidden-fields'>
                  <input
                    id='lsq-authKey'
                    name='lsq-authKey'
                    type='hidden'
                    value={lsqAuthKey}
                  />
                  <input
                    id='lsq-api-service-url'
                    name='lsq-api-service-url'
                    type='hidden'
                    value={lsqApiServiceUrl}
                  />
                  <input
                    id='lsq-app-url'
                    name='lsq-app-url'
                    type='hidden'
                    value={lsqAppUrl}
                  />
                  <input
                    id='lsq-process-id-hidden'
                    name='lsq-process-id-hidden'
                    type='hidden'
                    value={formId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
