import { Button } from './ui/button';
import { Reveal } from './utils/reveal';

export const Api = () => {
  return (
    <div className='mx-auto max-w-max'>
      <div className='flex flex-col items-center space-x-12 lg:flex-row'>
        <Reveal amount={0.6} delay={0.7}>
          <div>
            <img
              src='/api-1.svg'
              alt='Complete set of travel APIs across flights, hotels, cabs & more!'
              width={587}
              height={360}
            />
            {/* <p>Complete set of travel APIs across flights, hotels, cabs & more!</p> */}
          </div>
        </Reveal>

        <Reveal amount={0.6} delay={0.9}>
          <div>
            <img
              src='/api-2.svg'
              alt='The fastest response and requests returned with uptime of 99.99%'
              width={587}
              height={360}
              className='mt-2'
            />
            <p className='w-32ch text-white text-center mx-auto'>
              The fastest response and requests returned with uptime of 99.99%
            </p>
          </div>
        </Reveal>
      </div>

      <Reveal>
        <Button
          href='/api-customers/more-details'
          title='Get started with API'
          className='px-10 mx-auto mt-32 text-black bg-white'
          asInternalLink={true}
        />
      </Reveal>
    </div>
  );
};

export default Api;
