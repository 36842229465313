import Header from "../header/header";
import HeroSection from "../heroSection/heroSection";
import TopCustomers from "../topCustomers/topCustomers";
import ProductFeatures from "../productFeatures/productFeatures";
import SignupForm from "../signupForm/signupForm";
import Footer from "../footer/footer";

import AsianPaints from "../../assets/images/asian-paints.png";
import SaintGobain from "../../assets/images/saint-gobain.png";
import TataAia from "../../assets/images/tata-aia.png";
import BlueStar from "../../assets/images/blue-star.png";

import dashboard_customize from "../../assets/images/dashboard-customize.png";
import approval from "../../assets/images/approval.png";
import integration_instructions from "../../assets/images/integration-instructions.png";
import date_range from "../../assets/images/date-range.png";
import data_usage from "../../assets/images/data-usage.png";
import account_tree from "../../assets/images/account-tree.png";

const CorporateCustomersPage = () => {
  const ProductFeaturesimages = [
    {
      id: 1,
      Icons: dashboard_customize,
      text: " Manage & customize your travel policies for your organization",
    },
    {
      id: 2,
      Icons: approval,
      text: "Provision to implement travel bookings basis matrix approval structures",
    },
    {
      id: 3,
      Icons: integration_instructions,
      text: " Integration with various partners for best fares across flights, hotels & more",
    },
    {
      id: 4,
      Icons: date_range,
      text: "Flexible travel dates, low cancellation charges, free seats, and meal benefits.",
    },
    {
      id: 5,
      Icons: data_usage,
      text: "Key Reports under the MIS dashboard to track expenses & save costs",
    },
    {
      id: 6,
      Icons: account_tree,
      text: "    Accounting instruments, GSTR information, reconciliation modules.",
    },
  ];

  const images = [
    {
      id: 1,
      src: AsianPaints,
      alt: "AsianPaints",
    },
    {
      id: 2,
      src: SaintGobain,
      alt: "SaintGobain",
    },
    {
      id: 3,
      src: TataAia,
      alt: "TataAia",
    },
    {
      id: 4,
      src: BlueStar,
      alt: "BlueStar",
    },
  ];
  return (
    <div>
      <Header isMenuEnable={true} logoHeight={"100px"} location='ooo' />
      <HeroSection
        isLandingPage={false}
        HeroSectionHeading='All new customized tool'
        HeroSectionParagraph='Provide a seamless travel booking experience for your organization!'
      />
      <TopCustomers images={images} />
      <ProductFeatures ProductFeaturesimages={ProductFeaturesimages} />
      <SignupForm
        SignupFormHeading='Want to know more about our Corporate product?'
        SignupFormParagraph="Fill in the below details & we'll reach out to you!"
      />
      <Footer />
    </div>
  );
};

export default CorporateCustomersPage;
