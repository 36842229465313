import { Button } from "./ui/button";
import { cn } from "../lib/utils";

export const ProductPanel = ({
  title,
  subtitle,
  imageList,
  buttonTitle,
  linkHref,
  titleWidth = "lg:w-20ch",
  subtitleWidth = "lg:w-56ch",
}) => (
  <>
    <h1
      className={cn(
        "text-3xl text-white lg:text-5xl+ lg:leading-18 font-bold text-center mt-10 mx-auto",
        titleWidth
      )}
    >
      {title}
    </h1>
    <p
      className={cn(
        "text-xs text-white lg:text-xl text-center mt-6 w-48ch mx-auto",
        subtitleWidth
      )}
    >
      {subtitle}
    </p>
    <div className="mt-12 mx-auto lg:w-max flex flex-wrap justify-center gap-2 lg:space-x-6">
      {imageList.map(({ imageSrc, imageAlt }) => (
        <img
          key={imageAlt}
          src={imageSrc}
          alt={imageAlt}
          width={185}
          height={114}
        />
      ))}
    </div>
    <Button
      href={linkHref}
      title={buttonTitle}
      className="mx-auto mt-20"
      asInternalLink={true}
    />
  </>
);

export default ProductPanel;
