import axios from "axios";
import { useState } from "react";
import Dialog from "../../newComponents/ui/modal";

const ErrorText = ({ errorMsg }) => (
  <span id='emailError' className='text-red-600 text-xs'>
    {errorMsg}
  </span>
);

const Label = ({ label, htmlFor }) => (
  <label htmlFor={htmlFor} className='text-neu-black'>
    {label}
  </label>
);

const ConfLanding = () => {
  const NAME = "NAME";
  const EMAIL = "EMAIL";
  const heroImage = "/ipscon-hero.jpeg";
  const nameRegex = /^(?!.* {2})(?!.* $)[A-Za-z ]+$/;
  const alphabetAndSpaceRegex = /^[a-zA-Z ]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const origin = "O|PCXnr~AU+~Iv1_@nEZU[Y)-fO[%I";
  const apiEndPoint = "https://provider.dice.tech/origin/sme/ipson";
  const placeholders = {
    name: "Enter full name",
    email: "Enter email address",
  };
  const initialUserData = {
    [NAME]: "",
    [EMAIL]: "",
  };
  const initialErrorState = {
    [NAME]: "",
    [EMAIL]: "",
  };

  const [userData, setUserData] = useState(initialUserData);
  const [errors, setErrors] = useState(initialErrorState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionFailed, setSumbissionFailed] = useState(false);
  const [dialog, setDialog] = useState(false);

  const handleInputChange = (event, elementType) => {
    setErrors({
      ...errors,
      [elementType]: "",
    });
    switch (elementType) {
      case NAME:
        const { value: name } = event.target;
        if (!name) {
          setUserData({
            ...userData,
            [NAME]: "",
          });
        } else if (alphabetAndSpaceRegex.test(name)) {
          setUserData({
            ...userData,
            [NAME]: name,
          });
        }
        return;

      case EMAIL:
        const { value: email } = event.target;
        setUserData({
          ...userData,
          [EMAIL]: email,
        });
        return;
      default:
        return;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { [NAME]: name, [EMAIL]: email } = userData;
    let isError = false;
    let errorObj = {};

    if (!name || !nameRegex.test(name)) {
      isError = true;
      errorObj = {
        ...errorObj,
        [NAME]: "Please enter a valid name",
      };
    }
    if (!email || !emailRegex.test(email)) {
      isError = true;
      errorObj = {
        ...errorObj,
        [EMAIL]: "Enter a valid email address",
      };
    }

    if (isError) {
      setErrors({
        ...errors,
        ...errorObj,
      });
      return;
    }

    const payload = { name, email, origin };

    try {
      setIsSubmitting(true);
      setSumbissionFailed(false);
      const res = await axios.post(apiEndPoint, payload);
      if (res?.status === 200 || res?.status === 201) {
        setDialog(true);
      }
      console.log({ res });
    } catch (error) {
      console.error(error);
      setSumbissionFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDialogClose = () => {
    setUserData(initialUserData);
    setDialog(false);
  };

  return (
    <div className='md:pt-12'>
      <div className='max-w-3xl mx-auto md:border rounded md:my-4'>
        <div>
          <picture>
            <source srcSet={heroImage} />
            <img src={heroImage} alt='ipscon banner' />
          </picture>
        </div>
        <div className='px-2'>
          <h1 className='text-xl md:text-2xl text-center mt-4'>
            53rd Annual Conference of Indian Pharmacological Society
          </h1>
        </div>
        <form onSubmit={handleSubmit} className='p-6 pt-1 pb-8 mt-0'>
          {submissionFailed && (
            <p id='emailError' className='text-red-600 md:text-lg text-center'>
              Something went wrong! Please try again.
            </p>
          )}
          <div className='my-4'>
            <Label htmlFor={NAME} label='Name' />
            <input
              id={NAME}
              maxLength='255'
              placeholder={placeholders.name}
              name={NAME}
              size='20'
              type='text'
              autoFocus
              className='h-[38] rounded border p-2 w-full mt-2 text-neu-black'
              onChange={(e) => handleInputChange(e, NAME)}
              value={userData[NAME]}
            />
            {errors[NAME] ? <ErrorText errorMsg={errors[NAME]} /> : ""}
          </div>
          <div className='my-4'>
            <Label htmlFor={EMAIL} label='Email Address' />
            <input
              id={EMAIL}
              maxLength='80'
              placeholder={placeholders.email}
              name={EMAIL}
              size='20'
              type='text'
              className='h-[38] rounded border p-2 w-full mt-2 text-neu-black'
              onChange={(e) => handleInputChange(e, EMAIL)}
              value={userData[EMAIL]}
            />
            {errors[EMAIL] ? <ErrorText errorMsg={errors[EMAIL]} /> : ""}
          </div>

          <button
            type='submit'
            disabled={isSubmitting}
            className={`bg-conf-blue w-full py-1.5 px-4 mt-2 rounded font-bold text-lg flex items-center justify-center ${
              isSubmitting ? "cursor-not-allowed" : "cursor-auto"
            }`}
          >
            {isSubmitting && (
              <svg
                aria-hidden='true'
                class='inline w-6 h-6 mr-2 animate-spin '
                viewBox='0 0 100 101'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                  fill='currentColor'
                />
                <path
                  d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                  fill='#30aedb'
                />
              </svg>
            )}
            <span>{isSubmitting ? "Submitting..." : "Submit"}</span>
          </button>
        </form>
      </div>
      <Dialog open={dialog} onCloseModal={handleDialogClose}>
        <>
          <h2 className='text-neu-black text-2xl mb-4'>Sign Up Successful</h2>
          <p className='max-w-[400px]'>
            Hello{" "}
            <span className='font-semibold text-base'>{userData["NAME"]}</span>.
            You have successfully registered for booking. We have sent you an
            email at{" "}
            <span className='font-semibold text-base'>{userData["EMAIL"]}</span>{" "}
            containing login details to complete the booking process.
          </p>
          <button
            className={`bg-conf-blue w-full py-1.5 px-4 mt-4 rounded outline-none font-bold text-lg`}
            onClick={handleDialogClose}
          >
            Close
          </button>
        </>
      </Dialog>
    </div>
  );
};

export default ConfLanding;
